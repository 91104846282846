<template>
	<div id="gamelist-wrapper">
		<el-table :data="tableData" border>
			<el-table-column type="expand">
				<template slot-scope="props">
					<el-form label-position="left" inline class="table-expand">
						<el-form-item label="比赛名称">
							<span>{{ props.row.gamename }}</span>
						</el-form-item>
						<el-form-item label="比赛编号">
							<span>{{ props.row.gameid }}</span>
						</el-form-item>
						<el-form-item label="比赛全称">
							<span>{{ props.row.gamefullname }}</span>
						</el-form-item>
						<el-form-item label="负责协会">
							<span>{{ props.row.gameclubname }}</span>
						</el-form-item>
						<el-form-item label="比赛类型">
							<span>{{ gametype2string(props.row.gametype) }}</span>
						</el-form-item>
						<el-form-item label="比赛状态">
							<span>{{ props.row.gamestatus }}</span>
						</el-form-item>
						<el-form-item label="分数倍数">
							<span>{{ props.row.gamemultiple }}</span>
						</el-form-item>
						<el-form-item label="比赛属性">
							<span>{{ gameselectattr2string(props.row.gameselectattr) }}</span>
						</el-form-item>
						<el-form-item label="积分规则">
							<span>{{ props.row.gamescore }}</span>
						</el-form-item>
					</el-form>
				</template>
			</el-table-column>
			<el-table-column prop="gamename" label="比赛名称" width="150">
			</el-table-column>
			<el-table-column prop="gametype" label="比赛类型" width="90">
			</el-table-column>
			<el-table-column prop="gameid" label="比赛编号" width="90">
			</el-table-column>
			<el-table-column prop="gamefullname" label="比赛全称" min-width="200">
			</el-table-column>
			<el-table-column prop="gameclubname" label="负责院系" min-width="200">
			</el-table-column>
			<el-table-column prop="gamestatus" label="比赛状态" min-width="90">
			</el-table-column>
			<el-table-column label="操作" width="130">
				<template slot-scope="scope">
					<!-- 编辑 -->
					<el-tooltip v-if="!deleted" class="item" effect="dark" placement="top">
					<template slot="content">
						编辑该比赛的信息
					</template>
					<el-button v-if="!deleted" size="small" icon="el-icon-edit"
						@click="editItem(scope.row)" type="primary" plain circle>
					</el-button>
					</el-tooltip>
					<!-- 删除 -->
					<el-tooltip v-if="!deleted" class="item" effect="dark" placement="top">
					<template slot="content">
						删除该比赛——请谨慎操作<br>(可在已删除比赛界面恢复)
					</template>
					<el-button v-if="!deleted" size="small" icon="el-icon-delete"
						@click="deleteItem(scope.row)" type="danger" plain circle>
					</el-button>
					</el-tooltip>
					<!-- 恢复 -->
					<el-tooltip v-if="deleted" class="item" effect="dark" placement="top">
					<template slot="content">
						恢复已删除的该比赛——请谨慎操作
					</template>
					<el-button v-if="deleted" size="small" icon="el-icon-refresh-left"
						@click="recoverItem(scope.row)" type="warning" plain circle>
					</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog title="编辑比赛信息" :visible.sync="showEdit">
			<el-form :model="onEditItem">
			<el-form-item label="比赛名称" :label-width="formLabelWidth">
				<el-input v-model="onEditItem.gamename" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="比赛全称" :label-width="formLabelWidth">
				<el-input v-model="onEditItem.gamefullname" autocomplete="off"></el-input>
			</el-form-item>
			<!-- todo: 负责协会 -->
			<el-form-item label="负责协会" :label-width="formLabelWidth">
				<el-select v-model="onEditItem.gameclub" filterable @change="gameclubChange" placeholder="请选择">
					<el-option
						v-for="item in clubList"
						:key="item.clubid"
						:label="item.clubname"
						:value="item.clubid"
					>
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="比赛类型" :label-width="formLabelWidth">
			<el-select v-model="onEditItem.gametype" filterable placeholder="请选择">
				<el-option
					v-for="item in options"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				>
				</el-option>
			</el-select>
			</el-form-item>
			<el-form-item label="比赛属性" :label-width="formLabelWidth">
				<el-radio-group v-model="onEditItem.gameselectattr">
					<el-radio label="1"> 必选 </el-radio>
					<el-radio label="2"> 限选 </el-radio>
					<el-radio label="3"> 任选 </el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="分数倍数" :label-width="formLabelWidth">
				<el-input v-model="onEditItem.gamemultiple" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="积分规则" :label-width="formLabelWidth">
				<el-input v-model="onEditItem.gamescore" autocomplete="off"></el-input>
			</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="quitEdit()">取 消</el-button>
				<el-button type="primary" @click="submitEdit()">确 定</el-button>
			</div>
		</el-dialog>
		<el-backtop>
			<i class="el-icon-caret-top"></i>
		</el-backtop>
	</div>
</template>

<style>
.table-expand {
	font-size: 0;
	width: 88%;
	margin: auto;
}
.table-expand label {
	width: 90px;
	color: #99a9bf;
}
.table-expand .el-form-item {
	margin-right: 0;
	margin-bottom: 0;
	width: 50%;
}
</style>

<script>
import axios from "axios";
import qs from "qs";
export default {
	name: "GameList",

	data() {
		var oneItem = {
			gameid: 0,
			gamename: "",
			gamefullname: "",
			gameclub: 0,
			gameselectattr: "3",
			gamemultiple: 0,
			gamescore: "",
			gameclubname: "",
			gametype: "",
		};
		return {
			deleted: this.$route.query.deleted === "true" ? true : false,
			tableData: [],
			clubList: [],
			options: [
				{
					value: "马杯",
					label: "马杯比赛",
				},
				{
					value: "首高团体",
					label: "首高团体比赛",
				},
				{
					value: "首高个人",
					label: "首高个人比赛",
				},
				{
					value: "材料",
					label: "提交材料审核",
				},
			],
			onEditItem: oneItem,  // the item on edit
			preEditItem: oneItem,  // original content of the item which is being editing
			showEdit: false,  // show the edit dialog
			formLabelWidth: '120px',
		};
	},
	watch: {
		$route() {
			this.deleted = (this.$route.query.deleted === "true" ? true : false);
			this.getGameList();
		}
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
		},
		gameselectattr2string(gameselectattr) {
			// 将数字表示的比赛属性转换为汉字
			switch (gameselectattr) {
				case '1':
					return '必选';
				case '2':
					return '限选';
				case '3':
					return '任选';
				default:
					return '';
			}
		},
		gametype2string(gametype) {
			// 实现 传过来的缩写的type字段 向 全称的比赛类型 转换
			for (let item of this.options) {
				if (item.value === gametype) {
					return item.label;
				}
			}
			return '';
		},
		gameclubChange(clubItem) {
			// console.log(clubItem);
			// console.log(this.clubList.find((club) => {
			// 	return club.clubid == clubItem;
			// }).clubname);
			// 目的在于在编辑时，绑定下拉框选择的gameclubname（el-select只能绑定key）
			this.onEditItem.gameclubname = this.clubList.find((club) => {
				return club.clubid == clubItem;
			}).clubname;
		},
		editItem(row) {
			// console.log(row);
			this.showEdit = true;
			if (row.gameid != this.preEditItem.gameid) {
				this.preEditItem = JSON.parse(JSON.stringify(row));
				this.preEditItem.gamescore = this.preEditItem.gamescore.toString();
				this.onEditItem = JSON.parse(JSON.stringify(row));
				this.onEditItem.gamescore = this.onEditItem.gamescore.toString();
			}
		},
		submitEditCheck() {
			if (!this.onEditItem.gameid) {
				this.messageBox("请选择要修改的比赛", "error");
				return false;
			}
			if (!this.onEditItem.gamename) {
				this.messageBox("请输入比赛名称", "error");
				return false;
			}
			if (!this.onEditItem.gamefullname) {
				this.messageBox("请输入比赛全称", "error");
				return false;
			}
			if (!this.onEditItem.gameclub) {
				this.messageBox("请选择负责协会", "error");
				return false;
			}
			if (!this.onEditItem.gametype)
				if (!this.onEditItem.gamefullname) {
					this.messageBox("请选择比赛类型", "error");
					return false;
				}
			if (!this.onEditItem.gameselectattr) {
				this.messageBox("请选择比赛属性", "error");
				return false;
			}
			if (this.onEditItem.gamemultiple > 20 || this.onEditItem.gamemultiple < 1) {
				this.messageBox("分数倍数是一个1-20的整数", "error");
				return false;
			}
			if (!this.onEditItem.gamescore) {
				this.messageBox("请输入积分规则", "error");
				return false;
			}
			return true;
		},
		submitEdit() {
			// console.log(this.onEditItem);
			if (this.submitEditCheck() === true) {
			this.$confirm("此操作将修改"+this.preEditItem.gamename+"的信息, 是否提交当前修改？", "提示", {
				confirmButtonText: "确认",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(() => {
				// console.log(this.onEditItem);
				if (localStorage.getItem("token")) {
					axios.defaults.headers.common["Authorization"] = localStorage.getItem(
						"token"
					);
				}
				axios({
					url: "/api/admin/game/update",
					method: "POST",
					headers: {
						"content-type": "application/x-www-form-urlencoded",
					},
					data: qs.stringify({
						gameid: this.onEditItem.gameid,
						gamename: this.onEditItem.gamename,
						gamefullname: this.onEditItem.gamefullname,
						gametype: this.onEditItem.gametype,
						gameclub: this.onEditItem.gameclub,
						gameselectattr: this.onEditItem.gameselectattr,
						gamemultiple: this.onEditItem.gamemultiple,
						gamescore: "[" + this.onEditItem.gamescore + "]",
					}),
				})
				.then(async () => {
					// console.log(res);
					this.showEdit = false;
					this.messageBox("修改" + this.onEditItem.gamename + "信息成功", "success");
					await this.sleep(250);
					this.$router.go(0);
				})
				.catch((err) => {
					this.messageBox(
						"修改失败， " +
							err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Update game Failed");
					console.log(err.response);
				});
			})
			.catch(() => {})}
		},
		quitEdit() {
			// console.log(this.onEditItem);
			this.$confirm("此操作将退出修改页面, 是否暂存当前修改？<br><span style=\"font-size: smaller;\">（刷新页面或修改其他院系时清空；点击右上叉号返回继续修改）</span>", "提示", {
				confirmButtonText: "暂存修改并退出",
				cancelButtonText: "撤销修改并退出",
				type: "warning",
				distinguishCancelAndClose: true,
				dangerouslyUseHTMLString: true,
			})
			.then(() => {
				this.showEdit = false;
			})
			.catch((action) => {
				if (action === 'cancel') {
					this.showEdit = false;
					this.onEditItem = JSON.parse(JSON.stringify(this.preEditItem));
				}
			});
		},
		deleteItem(row) {
			this.$confirm("此操作将删除该比赛, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(() => {
				if (row.gameid != undefined) {
				axios({
					url: "/api/admin/game/delete",
					method: "POST",
					headers: {
						"content-type": "application/x-www-form-urlencoded",
					},
					data: qs.stringify({
						gameid: row.gameid,
					}),
				})
				.then(async () => {
					// console.log(res);
					await this.messageBox("删除比赛成功", "success");
					await this.sleep(250);
					this.$router.go(0);
				})
				.catch((err) => {
					this.messageBox(
						"删除比赛失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Delete Game Failed");
					console.log(err.response);
				});
				} else {
					this.messageBox("获取比赛信息失败，请检查网络连接或刷新界面", "error");
				}
			}).catch(() => {});
		},
		recoverItem(row) {
			this.$confirm("此操作将恢复该比赛, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(() => {
				if (row.gameid != undefined) {
				axios({
					url: "/api/admin/game/recover",
					method: "POST",
					headers: {
						"content-type": "application/x-www-form-urlencoded",
					},
					data: qs.stringify({
						gameid: row.gameid,
					}),
				})
				.then(async () => {
					// console.log(res);
					await this.messageBox("恢复比赛成功", "success");
					await this.sleep(250);
					this.$router.go(0);
				})
				.catch((err) => {
					this.messageBox(
						"恢复比赛失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Delete Game Failed");
					console.log(err.response);
				});
				} else {
					this.messageBox("获取比赛信息失败，请检查网络连接或刷新界面", "error");
				}
			}).catch(() => {});
		},
		getGameList() {
			// Return the list of games, the parameter deleted is default 0, means not deleted; 1 for have deleted
			axios({
				url: "/api/admin/game/list",
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
				params: {
					deleted: this.deleted === true ? 1 : 0,
				},
			})
				.then((res) => {
					this.tableData = res.data.data;
					let status_trans = function(status) {
						if (status == -1) {
							return "结果未提交";
						}
						if (status == 0) {
							return "提交待审核"
						}
						if (status == 1) {
							return "审核未通过"
						}
						return "完成提交"
					}
					for (let game of this.tableData) {
						game.gamestatus = status_trans(game.gamestatus);
						if (game.gametype === '材料') game.gamestatus = '';
					}
				})
				.catch((err) => {
					this.messageBox(
						"获取比赛列表失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Get game list Failed");
					console.log(err.response);
					return -1;
				});
		},
	},
	beforeMount() {
		if (localStorage.getItem("token")) {
			axios.defaults.headers.common["Authorization"] = localStorage.getItem(
				"token"
			);
		}
		axios({
			url: "/api/admin/asso/get?type=club",
			method: "GET",
			headers: {
				"content-type": "application/x-www-form-urlencoded",
			},
		})
			.then((res) => {
				this.clubList = res.data.data;
			})
			.catch((err) => {
				this.messageBox(
					"获取协会列表失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
					"error"
				);
				console.log("Get clubList Failed");
				console.log(err.response);
			});
	},
	mounted() {
		this.getGameList();
	},
};
</script>
